<template>
  <div class="min-h-screen bg-gray-100 p-8">
    <div class="max-w-6xl mx-auto">
      <h1 class="text-3xl font-bold text-center mb-8">Logan AI</h1>
      <p class="text-lg text-center text-gray-700 mb-8">
        An AI-powered platform that revolutionizes CRUD operations and live dataset queries.
        Logan AI bridges the gap between natural language interactions and robust backend integrations,
        enabling seamless data management and real-time insights.
      </p>
      <!-- Main Layout -->
      <div class="grid grid-cols-3 gap-4">
        <!-- AI Chat Section -->
        <div class="col-span-2 bg-white shadow-md rounded-lg p-4">
          <h2 class="text-xl font-semibold mb-4">Logan Chat</h2>
          <p class="text-sm text-gray-600 mb-4">
            Ask Logan questions in natural language and watch it seamlessly interact with live data from the backend.
            Whether you're querying the database or performing actions, Logan provides instant and intelligent responses powered by advanced AI.
          </p>
          <div ref="chatBox" class="chat-box h-96 overflow-y-auto border border-gray-300 rounded-md p-4 mb-4">
            <div v-for="(message, index) in chatMessages" :key="index" class="mb-4">
              <div
                  :class="{
                  'text-right text-blue-700': message.role === 'user',
                  'text-left text-gray-700': message.role === 'system',
                }"
              >
                <p class="mb-1 font-medium">
                  {{ message.role === 'user' ? 'You' : 'Logan' }}:
                </p>
                <p>{{ message.content }}</p>
              </div>
            </div>
          </div>

          <form @submit.prevent="sendMessage" class="flex flex-col">
            <div class="relative flex items-center">
    <textarea
        v-model="inputMessage"
        placeholder="Type your question here"
        class="w-full p-2 border border-gray-300 rounded-md mb-4"
        rows="3"
    ></textarea>

            </div>
            <button
                type="submit"
                class="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-md w-full"
            >
              Send
            </button>
          </form>
        </div>

        <!-- Data Table Section -->
        <div class="col-span-1 bg-white shadow-md rounded-lg p-4">
          <h2 class="text-xl font-semibold mb-4">A live database view</h2>
          <p class="text-xs text-gray-600 mb-4">
            The data displayed here is fetched in real-time from a live DigitalOcean database.
            Every action performed or query made updates this view instantly, showcasing the power of AI-driven backend integration.
          </p>
          <div class="overflow-y-auto max-h-[450px] border border-gray-300 rounded-md">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs uppercase bg-gray-200 text-gray-700">
              <tr>
                <th class="py-2 px-4">First Name</th>
                <th class="py-2 px-4">Last Name</th>
                <th class="py-2 px-4">Email</th>
                <th class="py-2 px-4">Status</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="user in users"
                  :key="user.id"
                  class="border-b hover:bg-gray-100"
              >
                <td class="py-2 px-4">{{ user.first_name }}</td>
                <td class="py-2 px-4">{{ user.last_name }}</td>
                <td class="py-2 px-4">{{ user.email }}</td>
                <td
                    class="py-2 px-4"
                    :class="{
                      'text-green-500': user.status === 'Active',
                      'text-yellow-500': user.status === 'Pending',
                      'text-red-500': user.status === 'Inactive',
                    }"
                >
                  {{ user.status }}
                </td>
              </tr>
              <tr v-if="users.length === 0">
                <td colspan="4" class="text-center py-2 px-4 text-gray-500">
                  No user accounts available.
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "../api/apiClient";

export default {
  data() {
    return {
      users: [],
      chatMessages: [],
      inputMessage: "",
      isListening: false,
      recognition: null,
      conversationId: null,
    };
  },
  methods: {
    initializeSpeechRecognition() {
      const SpeechRecognition =
          window.SpeechRecognition || window.webkitSpeechRecognition;

      if (!SpeechRecognition) {
        alert(
            "Your browser does not support speech recognition. Please use Chrome or Edge."
        );
        return;
      }

      this.recognition = new SpeechRecognition();
      this.recognition.lang = "en-US"; // Set language
      this.recognition.interimResults = false;
      this.recognition.continuous = false;

      // Handle recognition events
      this.recognition.onstart = () => {
        this.isListening = true;
      };
      this.recognition.onresult = (event) => {

        console.log("Speech recognition result event:", event);
        if (event.results && event.results[0] && event.results[0][0]) {
          const transcript = event.results[0][0].transcript;
          console.log("Recognized transcript:", transcript);
          this.inputMessage += transcript; // Update the inputMessage field
        } else {
          console.warn("No speech result detected.");
        }
      };
      this.recognition.onerror = (error) => {
        console.error("Speech recognition error:", error);

        switch (error.error) {
          case "network":
            alert(
                "Network error: Please check your internet connection and try again."
            );
            break;
          case "not-allowed":
            alert(
                "Permission denied: Please allow microphone access in your browser settings."
            );
            break;
          case "no-speech":
            alert("No speech detected. Please try speaking again.");
            break;
          case "audio-capture":
            alert(
                "No microphone detected. Please ensure your microphone is connected and working."
            );
            break;
          default:
            alert(`An unexpected error occurred: ${error.error}`);
        }
      };
      this.recognition.onend = () => {
        this.isListening = false;
      };
    },
    startVoiceInput() {
      if (!this.recognition) this.initializeSpeechRecognition();

      if (this.isListening) {
        console.log("Stopping speech recognition...");
        this.recognition.stop();
      } else {
        console.log("Starting speech recognition...");
        this.recognition.start();
      }
    },
    async fetchUsers() {
      try {
        const response = await apiClient.get("/user-accounts/index");
        // Correctly access users array and map status to labels
        this.users = response.data.data.map(user => ({
          ...user,
          status: user.status === 1 ? 'Active' : user.status === 2 ? 'Inactive' : 'Pending',
        }));
      } catch (error) {
        console.error("Error fetching users:", error);
        alert("Failed to load user accounts.");
      }
    },
    scrollToBottom() {
      const chatBox = this.$refs.chatBox;
      if (chatBox) {
        chatBox.scrollTop = chatBox.scrollHeight; // Scroll to the bottom
      }
    },
    async sendMessage() {
    if (!this.inputMessage.trim()) return;

    // Add user message to chat
    this.chatMessages.push({ role: "user", content: this.inputMessage });

    try {
        const response = await apiClient.post("/handle_request", {
            input_text: this.inputMessage,
            user_id: "1", // Mocked user ID
            microservice: "user_accounts",
            conversation_id: this.conversationId, // Send the conversation ID if it exists
        });

        // Save the conversation ID if it's provided
        if (response.data.conversation_id) {
            this.conversationId = response.data.conversation_id;
        }

        // Add AI response to chat
        this.chatMessages.push({
            role: "system",
            content: response.data.response,
        });

        // Refresh user data if updated
        this.fetchUsers();
    } catch (error) {
        console.error("Error sending message:", error);
        this.chatMessages.push({
            role: "system",
            content: "An error occurred while processing your request.",
        });
    }
    this.scrollToBottom();
    // Clear input field
    this.inputMessage = "";
},
  },
  mounted() {
    this.fetchUsers();
    this.initializeSpeechRecognition();
  },
  watch: {
    chatMessages() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
};
</script>